<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-07-19 09:24:50
 * @Description: 招聘信息详情
-->
<template>
  <div>
    <div class="header">
      <ds-header title="招聘信息查看" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <div class="content">
      <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-model-item label="企业名称"> {{ form.enterpriseName }} </a-form-model-item>
        <a-form-model-item label="岗位名称"> {{ form.jobName }} </a-form-model-item>
        <a-form-model-item label="岗位类型">{{ form.jobTypeIds }} </a-form-model-item>
        <a-form-model-item label="学历要求">{{ form.degreeRequireName }} </a-form-model-item>
        <a-form-model-item label="工作年限要求"> {{ form.workingYearsRequireName }} </a-form-model-item>
        <a-form-model-item label="职责描述"> <span v-html="form.jobDescription"></span> </a-form-model-item>
        <a-form-model-item label="岗位要求"><span v-html="form.jobRequire"></span></a-form-model-item>
        <a-form-model-item label="就职园区"> {{ form.projectName }} </a-form-model-item>
        <a-form-model-item label="薪资类型"> {{ salaryType }} </a-form-model-item>
        <a-form-model-item label="薪资区间" v-if="form.salaryType === 0 ||form.salaryType === 2">
          {{ form.salaryRangeMin }} {{ form.salaryRangeMax?'-'+form.salaryRangeMax:'' }}{{form.salaryType === 0?'K':'元/天'}}{{ form.annualSalary?" · "+form.annualSalary+'薪':'' }}</a-form-model-item
        >
        <a-form-model-item label="简历投递邮箱"> {{ form.email }} </a-form-model-item>
        <a-form-model-item label="是否发布"> {{ form.publishStatus === 0 ? "已发布" : "未发布" }} </a-form-model-item>
        <a-form-model-item label="创建人"> {{ form.createName }} </a-form-model-item>
        <a-form-model-item label="创建时间"> {{ form.gmtCreate }} </a-form-model-item>
        <a-form-model-item label="发布人"> {{ form.publishName || "暂无" }} </a-form-model-item>
        <a-form-model-item label="发布时间"> {{ form.publishTime || "暂无" }} </a-form-model-item>
        <a-form-model-item label="最后修改人"> {{ form.modifiedName }}</a-form-model-item>
        <a-form-model-item label="最后修改时间"> {{ form.gmtModified }} </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import * as api from "../../../../api/socialRecruitingServicesManagement";
export default {
  name: "detail",
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    // 获取详情数据
    this.getDetail();
  },
  computed: {
    // 默认选中
    salaryType() {
     switch (this.form.salaryType) {
      case 0:
        return '月薪'
        break;
      case 2:
        return '日薪'
        break;
     
      default:
        return "面议"
        break;
     }
    },
  },
  methods: {
    // 获取详情数据
    async getDetail() {
      try {
        const recruitmentId = this.$route.query.id;
        const res = await api.recruitmentDetail(recruitmentId);
        if (res.code === "200") {
          this.form = res.data;
          this.form.jobDescription = res.data.jobDescription
            .replaceAll(/\r\n/g, "<br>")
            .replaceAll(/\r/g, "<br>")
            .replaceAll(/\n/g, "<br>");
          this.form.jobRequire = res.data.jobRequire
            .replaceAll(/\r\n/g, "<br>")
            .replaceAll(/\r/g, "<br>")
            .replaceAll(/\n/g, "<br>");
          let temp = "";
          res.data.jobTypeList.forEach((item) => {
            temp = temp + "，" + item.jobTypeName;
          });
          this.form.jobTypeIds = temp.substring(1);
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.content {
  width: 70%;
  margin: 10px auto;
}

</style>